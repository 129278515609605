import './App.css';
import react from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

class Posts extends react.Component {
    constructor() {
        super();
        this.state = { posts: [] };
    }

    async componentDidMount() {
        let postsFetched = [];

        try {
            const response = await fetch('https://posts.gpeixoto.workers.dev/posts', {cache: 'default'});

            if (!response.ok) {
                alert("HTTP-Error: " + response.status);
            }

            postsFetched = await response.json();

        } catch (err) {
            alert(err);
        }
        
        const postComponents = postsFetched.map(post => <Post key={post.key} title={post.title} username={post.username} content={post.content} />);

        this.setState({posts: postComponents});
    }
    
    render() {
        return (
            <div className="posts">
                <Typography gutterBottom variant="h2" component="div" textAlign="center" style={{ fontWeight: 450 }} mb={10}>Posts</Typography>
                {this.state.posts}
            </div>
        );
    }
}

class Post extends react.Component {
    render() {
        return (
            <div className="post">
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Grid container display="flex" alignItems="center" justify="space-between" spacing={2}>
                        <Grid item xs={8}>
                            <Typography gutterBottom variant="h3" component="div" style={{fontSize: 30}} mt={2}>
                            {this.props.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography gutterBottom variant="h6" component="div" align="right">
                            by {this.props.username}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" variant="body2" mb={2}>
                                {this.props.content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" /> 
            </div>
        );
    }
}

class SubmitPostButton extends react.Component {
    render() {
        return (
            <div className="submitPostButton">
                <Button style={{margin: "50px", float: "right"}} variant="contained" component={Link} to="/submit" endIcon={<SendIcon />}>
                    Submit Post
                </Button>
            </div>
        );
    }
}

function App() {
    return (
        <div className="App">
            <SubmitPostButton />
            <Posts />
        </div>
    );
}

export default App;