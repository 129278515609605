import react from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


class SubmitPost extends react.Component {
    async handleSubmit(event) {
        event.preventDefault();

        let post = {
            "title": event.target.form.postTitle.value,
            "username": event.target.form.postUsername.value,
            "content": event.target.form.postContent.value
        };

        try {
            console.log(post);
            const response = await fetch("https://posts.gpeixoto.workers.dev/posts", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(post)
            });

            if (!response.ok) {
                alert("HTTP-Error: " + response.status);
            }

        } catch (err) {
            alert(err);
        }

        window.location.href = "/";
    }

    render() {
        return (
            <div className="submitPostForm">
                <Box component="form">
                    <Grid container direction="column" rowSpacing={2} alignItems="center">
                        <Grid item xs>
                            <Input placeholder="Title" type="text" id="postTitle" multiline inputProps={{maxLength:30}}></Input><br></br>
                        </Grid>

                        <Grid item xs>
                            <Input placeholder="Username" type="text" id="postUsername" inputProps={{maxLength:20}}></Input><br></br>
                        </Grid>

                        <Grid item xs>
                            <Input placeholder="Content" type="text" id="postContent" multiline inputProps={{maxLength:140}}></Input><br></br>
                        </Grid>

                        <Grid item xs>
                            <Button variant="contained" onClick={this.handleSubmit}>Submit</Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export default SubmitPost;